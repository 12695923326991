import React from "react"
import { graphql } from "gatsby"
import { Sticky } from "semantic-ui-react"
import { useWindowScroll } from "react-use"
import styled from "styled-components"
import "semantic-ui-css/components/sticky.min.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import {
  Section,
  NavLink,
  Pagehead,
  PageTitle,
  Breadcrumbs,
} from "../components/shared"

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 7fr 3fr;
  gap: 1em;
`

const StickyWrapper = styled.div`
  position: relative;
  z-index: 0;
`

const ScrollSectionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media only screen and (max-width: 800px) {
    display: none; /* TODO: */
  }
`

const ScrollSectionListItem = styled.li`
  font-size: 1rem;
  position: relative;
  padding: 0.4em 0 0.2em;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5em;
    border-top: 2px solid;
    pointer-events: none;
  }
`

const ScrollSectionContainer = styled.div`
  scroll-margin-top: 100px;

  & + & {
    margin-top: 4em;
  }
`

const ScrollSectionHeading = styled.h2`
  margin-top: 0;
`

const ParagraphWithLinebreaks = styled.p`
  white-space: pre-line;
`

const ScrollSection = ({ heading, text, id, ref }) => {
  return (
    <ScrollSectionContainer id={id} ref={ref}>
      <ScrollSectionHeading>{heading}</ScrollSectionHeading>
      <RichText node={text} paragraphComponent={ParagraphWithLinebreaks} />
    </ScrollSectionContainer>
  )
}

const ImpressumDatenschutz = ({ data }) => {
  // TODO: consolidate data in one page on Contentful
  const imprintPageData = data.contentfulImprintPage
  const privacyPageData = data.contentfulPrivacyPage

  const sections = [
    {
      name: "Impressum",
      text: imprintPageData.text,
    },
    {
      name: "Datenschutz",
      text: privacyPageData.text,
    },
  ]

  const stickyContainerRef = React.useRef()

  // number of sections is guaranteed to be constant
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sectionRefs = sections.map(() => React.useRef())

  const { y } = useWindowScroll()

  const [activeSectionIndex, setActiveSectionIndex] = React.useState()
  React.useEffect(() => {
    const sectionOffsets = sectionRefs.map(
      sectionRef => sectionRef.current?.offsetTop ?? Infinity
    )

    const headerOffset = 200
    const activeSectionIndex = sectionOffsets.reduceRight(
      (foundIndex, currentOffset, currentIndex) => {
        if (foundIndex) {
          return foundIndex
        }
        if (y + headerOffset > currentOffset) {
          return currentIndex
        }
        return undefined
      },
      undefined
    )
    setActiveSectionIndex(activeSectionIndex)
  }, [y, sectionRefs])

  return (
    <Layout>
      <SEO
        title={`${imprintPageData.title} und ${privacyPageData.title}`}
        description={imprintPageData.metaDescription}
      />
      <Pagehead>
        <PageTitle>
          <RichText node={imprintPageData.pageTitle} unwrapParagraphs /> und{" "}
          <RichText node={privacyPageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <Breadcrumbs nodes={[{ title: "Impressum und Datenschutz" }]} />
      </Pagehead>
      <Section>
        <GridLayout>
          <StickyWrapper>
            <Sticky context={stickyContainerRef} offset={140}>
              <ScrollSectionList>
                {sections.map((section, index) => (
                  <ScrollSectionListItem key={section.name}>
                    <NavLink
                      to={`#${section.name
                        .toLowerCase()
                        .replace(/,?\s+/, "-")}`}
                      className={
                        index === activeSectionIndex ? "active" : undefined
                      }
                    >
                      {section.name}
                    </NavLink>
                  </ScrollSectionListItem>
                ))}
              </ScrollSectionList>
            </Sticky>
          </StickyWrapper>
          <div ref={stickyContainerRef}>
            {sections.map(({ name, text }, index) => (
              <ScrollSection
                heading={name}
                text={text}
                id={name.toLowerCase().replace(/,?\s+/, "-")}
                ref={sectionRefs[index]}
              />
            ))}
          </div>
          <div />
        </GridLayout>
      </Section>
      <Contact />
    </Layout>
  )
}

export default ImpressumDatenschutz

export const query = graphql`
  query ImpressumDatenschutzQuery {
    contentfulImprintPage {
      title
      metaDescription
      pageTitle {
        json
      }
      text {
        json
      }
    }
    contentfulPrivacyPage {
      title
      metaDescription
      pageTitle {
        json
      }
      text {
        json
      }
    }
  }
`
